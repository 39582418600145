'use client';

import { Link, Icon } from '@theme/components';
import { useSession } from 'next-auth/react';
import { ROUTES } from '@theme/routes';
import clsx from 'clsx';

import { closeMobileMenu } from '@akinon/next/redux/reducers/header';
import { useAppDispatch } from '@akinon/next/redux/hooks';
import { useLocalization } from '@akinon/next/hooks';

interface UserMenuProps {
  isMobile: boolean;
  className?: string;
}

export const UserMenu = (props: UserMenuProps) => {
  const { isMobile, className } = props;
  const { data: session, status } = useSession();
  const { t } = useLocalization();
  const dispatch = useAppDispatch();

  const MenuItems = [
    {
      url: ROUTES.AUTH,
      label: t('common.header.signup'),
      dataTestId: `header-signup${isMobile ? '-mobile' : ''}`
    },
    {
      url: ROUTES.AUTH,
      label: t('common.header.login'),
      dataTestId: `header-login${isMobile ? '-mobile' : ''}`
    }
  ];

  return (
    <ul
      className={clsx(
        'h-12 items-center ',
        isMobile ? 'flex text-sm' : 'hidden sm:flex',
        status != 'authenticated' && className
      )}
      id="user-menu"
    >
      {status === 'authenticated' ? (
        <li>
          <Link
            href={ROUTES.ACCOUNT}
            data-testid={`header-user${isMobile ? '-mobile' : ''}`}
            className="flex items-center space-x-2.5"
            onClick={() => dispatch(closeMobileMenu())}
          >
            <Icon name="user" size={24} />
            <span className="uppercase">{`${session.user.firstName} ${session.user.lastName}`}</span>
          </Link>
        </li>
      ) : (
        MenuItems.map((link, index) => (
          <li
            key={index}
            className={clsx(
              'camelcase flex items-center ',
              isMobile ? '' : 'px-2'
            )}
          >
            <Link
              href={link.url}
              passHref={true}
              data-testid={link.dataTestId}
              className="text-base"
            >
              {link.label}
            </Link>
            {index === 0 && <span className="mx-2">/</span>}
          </li>
        ))
      )}
    </ul>
  );
};
