'use client';

import { useMemo } from 'react';
import { useAutocompleteQuery } from '@akinon/next/data/client/misc';
import { ROUTES } from '@theme/routes';

import { LoaderSpinner, Price, Link } from '@theme/components';
import { useDebounce, useLocalization } from '@akinon/next/hooks';
// eslint-disable-next-line @akinon/projectzero/image-import
import Image from 'next/image';
import clsx from 'clsx';

interface ResultsProps {
  searchText: string;
}

const MINIMUM_SEARCH_LENGTH = 3;

export default function Results(props: ResultsProps) {
  const { searchText } = props;
  const { t } = useLocalization();
  const debouncedSearchText = useDebounce(searchText, 400);
  const { currentData, isFetching, isLoading } = useAutocompleteQuery(
    debouncedSearchText,
    {
      refetchOnMountOrArgChange: true,
      skip: debouncedSearchText.length < MINIMUM_SEARCH_LENGTH
    }
  );

  const categories = useMemo(
    () =>
      currentData?.groups.find((group) => group.suggestion_type === 'Category')
        ?.entries ?? [],
    [currentData]
  );

  const products = useMemo(
    () =>
      currentData?.groups.find((group) => group.suggestion_type === 'Product')
        ?.entries ?? [],
    [currentData]
  );

  if (
    debouncedSearchText.length < MINIMUM_SEARCH_LENGTH ||
    searchText !== debouncedSearchText
  ) {
    return null;
  }

  if (isLoading || isFetching) {
    return <LoaderSpinner />;
  }

  if (categories.length === 0 && products.length === 0) {
    return <p className="text-center">{t('common.search.not_found')}</p>;
  }

  return (
    <div className={clsx('flex w-full flex-wrap gap-4', 'md:gap-0')}>
      {categories.length > 0 && (
        <div className={clsx('flex w-full flex-col px-4', 'lg:px-6')}>
          <ul className="flex flex-col gap-4">
            {categories.map((category, index) => (
              <li
                key={index}
                className=" flex items-center justify-between gap-3 text-sm text-primary"
              >
                <Link className="text-sm text-gray-850" href={category.url}>
                  {category.label
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: category.label.replace(
                          new RegExp(searchText, 'gi'),
                          (match) =>
                            `<strong class="text-primary font-semibold">${match}</strong>`
                        )
                      }}
                    ></span>
                  ) : (
                    category.label
                  )}
                </Link>
                <span
                  className={clsx(
                    'max-w-[115px] text-xs font-medium text-secondary-500',
                    'lg:max-w-fit lg:text-sm'
                  )}
                >
                  {category?.extra?.parent_categories.slice(0, 2).join('/')}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="hidden flex-1 flex-col gap-6 px-4">
        <h6 className="font-semibold">
          {t('common.search.products_for')}{' '}
          <span className="uppercase text-secondary">
            {debouncedSearchText}
          </span>
        </h6>
        <div className="grid grid-cols-2 gap-8 sm:grid-cols-4">
          {products.map((product, index) => (
            <Link href={product?.url} key={index} className="flex flex-col">
              <div className="relative aspect-[315/448]">
                {product.extra.image ? (
                  <Image
                    src={product.extra.image}
                    alt={product?.label}
                    fill
                    sizes="(min-width: 320px) 164px,
                     (min-width: 640px) 50vw,
                     (min-width: 1160px) 315px"
                  />
                ) : (
                  <Image
                    className="h-full object-cover"
                    src="/noimage.jpg"
                    alt={product?.label}
                    fill
                    sizes="100vw"
                  />
                )}
              </div>
              <span className="mt-2 text-sm">{product?.label}</span>
              <Price
                value={product?.extra?.price}
                className="text-sm font-semibold"
              />
            </Link>
          ))}
        </div>
        <Link
          href={`${ROUTES.LIST}/?search_text=${debouncedSearchText}`}
          data-testid="search-view-all"
          className="w-80 border border-primary px-10 py-3 text-center text-xs font-semibold transition-all hover:bg-primary hover:text-white"
        >
          {t('common.search.view_all')} {debouncedSearchText.toUpperCase()}
        </Link>
      </div>
    </div>
  );
}
