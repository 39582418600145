'use client';

import { LanguageSelect } from '@theme/components';
import { CurrencySelect } from 'components/currency-select';
import { Image } from '@akinon/next/components/image';
import { Link } from '@theme/components';
import { twMerge } from 'tailwind-merge';

export default function HeaderBand(props) {
  const { data, className } = props;

  return (
    <div
      className={twMerge(
        'fixed top-0 z-50 h-10 w-full bg-primary lg:relative lg:h-14',
        className
      )}
    >
      {Object.keys(data ?? {}).length != 0 && (
        <div className="relative flex justify-center py-[10px] sm:container lg:justify-between lg:py-4">
          <Image
            src={
              data?.attributes?.header_band_image?.kwargs?.url ?? '/noimage.jpg'
            }
            alt="Header Band Image"
            fetchPriority="high"
            loading="eager"
            width={135}
            height={25}
            sizes="135px"
            className="block h-[21px] w-[135px] lg:h-[25px]"
          />

          <div className="hidden lg:block">
            <ul className="flex gap-8 text-sm text-gray-500">
              {data?.attributes?.header_band_items.map((item, index) => (
                <li
                  className='relative after:absolute after:-right-4 after:top-[3px]  after:h-4 after:border-r after:border-r-gray-900 after:content-[""]'
                  key={index}
                >
                  <Link href={item.value.redirect_url}>{item.value.name}</Link>
                </li>
              ))}
              <li className="flex items-start">
                <LanguageSelect className="!h-auto !w-full px-0 text-sm opacity-0 " />
                <CurrencySelect className="hidden !h-auto !w-full px-0 text-sm opacity-0" />
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
