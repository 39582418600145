import clsx from 'clsx';
import { SwiperNavigation, SwiperReact, SwiperSlide } from '@theme/components';
import { ProductItem } from '@theme/views/product-item';
import { useGetWidgetQuery } from '@akinon/next/data/client/misc';

export default function SearchModalRecommendation() {
  const { data } = useGetWidgetQuery('search-recommendation-products');

  const attributes = data?.attributes;

  if (Object.keys(attributes || {}).length === 0) return;

  const products = data?.products;

  if (!Array.isArray(products) || !products.length) return;

  const title = attributes?.title.value;

  return (
    <div
      className={clsx('px-4', 'lg:px-6')}
      data-id="search-product-recommendation"
    >
      {title && (
        <div
          className={clsx(
            'mb-6 text-lg font-medium leading-none',
            'lg:text-2xl'
          )}
        >
          {title}
        </div>
      )}

      <SwiperReact
        slidesPerView={1.915}
        className="flex !overflow-visible"
        spaceBetween={16}
        breakpoints={{ 1024: { slidesPerView: 'auto', spaceBetween: 24 } }}
        modules={[SwiperNavigation]}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }}
      >
        {data?.products?.map((product, index: number) => {
          return (
            <SwiperSlide
              className="flex-shrink-0 lg:!w-[200px]"
              key={`account-recommendation-product-${index}`}
            >
              <ProductItem
                className="[&_.h-11]:h-auto [&_.product-badge]:hidden"
                width={113}
                height={130}
                index={index}
                product={product}
                fetchPriority="low"
                imageLoading="lazy"
              />
            </SwiperSlide>
          );
        })}
        <div
          className={clsx(
            `swiper-button-prev swiper-button-prev::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
            `after:!text-[.75rem] after:text-primary after:content-['prev']`,
            'lg:bg-primary lg:after:text-white'
          )}
        ></div>
        <div
          className={clsx(
            `swiper-button-next swiper-button-next::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
            `after:!text-[.75rem] after:text-primary after:content-['next']`,
            'lg:bg-primary lg:after:text-white'
          )}
        ></div>
      </SwiperReact>
    </div>
  );
}
