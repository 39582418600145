'use client';

import clsx from 'clsx';
import { useGetBasketQuery } from '@akinon/next/data/client/basket';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import { closeMiniBasket } from '@akinon/next/redux/reducers/root';
import { BasketItem } from '@akinon/next/types';

import { Price, Icon, LoaderSpinner, Link } from '@theme/components';
import { ROUTES } from '@theme/routes';
import { useLocalization, useMediaQuery } from '@akinon/next/hooks';
import { Image } from '@akinon/next/components/image';
import { useEffect, useState } from 'react';

function MiniBasketCarousel(props: { sortedBasket: BasketItem[] }) {
  const { sortedBasket } = props;

  return (
    <>
      <div
        className={clsx(
          'remove-scrollbar flex max-h-[18rem] flex-col gap-4 overflow-y-auto'
        )}
      >
        {sortedBasket?.map((basketItem, index) => (
          <div
            key={`mini-basket-${index}`}
            className="flex !h-[8.5rem] flex-col gap-2 border border-lightBlue px-2 py-4 md:p-4"
          >
            <div className="flex gap-4 ">
              <Link
                href={basketItem.product.absolute_url}
                className={'block shrink-0 transition-all duration-300'}
              >
                <Image
                  src={basketItem.product.productimage_set?.[0]?.image ?? ''}
                  alt={basketItem.product.name}
                  width={48}
                  height={80}
                  className="transition-all duration-300"
                />
              </Link>
              <div className="flex flex-1 flex-col">
                <Link
                  href={basketItem.product.absolute_url}
                  className="block text-sm leading-snug"
                  data-testid="mini-basket-item-name"
                >
                  {basketItem.product.name}
                </Link>
              </div>
            </div>
            <div className="justify-start text-sm font-semibold md:text-base">
              {parseFloat(basketItem.retail_price) >
                parseFloat(basketItem.price) && (
                <Price
                  value={
                    parseFloat(basketItem.retail_price) * basketItem.quantity
                  }
                  className="mr-2 line-through"
                />
              )}
              <Price value={basketItem.total_amount} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

interface MiniBasketProps {
  isHovered: boolean;
}

export default function MiniBasket(props: MiniBasketProps) {
  const { isHovered } = props;
  const matches = useMediaQuery('(min-width: 64rem)');
  const [isDesktop, setIsDesktop] = useState(false);
  const { open: miniBasketOpen } = useAppSelector(
    (state) => state.root.miniBasket
  );
  const dispatch = useAppDispatch();
  const { data: basket, isLoading, isSuccess } = useGetBasketQuery();
  const { t } = useLocalization();
  useEffect(() => {
    setIsDesktop(matches);
  }, [matches]);

  return (
    <>
      <div
        className={clsx(
          (isHovered || miniBasketOpen) && isDesktop
            ? 'visible lg:invisible lg:opacity-100'
            : 'invisible opacity-0',
          'fixed left-0 top-0 z-50 h-screen w-screen bg-black bg-opacity-80 transition-all duration-300'
        )}
        onClick={() => {
          dispatch(closeMiniBasket());
        }}
      />
      <div
        id="mini-basket-overlay"
        className={clsx(
          'overflow-hidden',
          isSuccess && basket?.basketitem_set.length >= 2
            ? 'lg:h-[28.25rem]'
            : 'lg:h-auto',
          (isHovered || miniBasketOpen) && isDesktop
            ? 'visible flex flex-col opacity-100 lg:translate-y-[calc(100%)]'
            : 'invisible translate-x-full opacity-0 lg:translate-x-0 lg:translate-y-[calc(100%)]',
          'fixed bottom-0 right-0 z-50 h-screen w-80 bg-white p-6 transition-all duration-300 lg:absolute lg:-bottom-[1.8rem] lg:w-[21.375rem] lg:border-b lg:border-l lg:border-r lg:border-lightBlue'
        )}
      >
        <header className="flex items-center gap-2 border-b pb-3 lg:hidden">
          <Icon
            name="close"
            size={24}
            className="fill-primary hover:cursor-pointer"
            onClick={() => dispatch(closeMiniBasket())}
          />
        </header>
        {isLoading ? (
          <LoaderSpinner />
        ) : isSuccess && basket?.basketitem_set?.length > 0 ? (
          <MiniBasketCarousel sortedBasket={basket?.basketitem_set} />
        ) : (
          <div className="flex h-full flex-col items-center justify-center">
            <span className="text-xl  font-light text-secondary">
              {t('basket.empty.title')}
            </span>
          </div>
        )}
        <footer className="mt-auto flex flex-col gap-3 pt-4 lg:flex-1 lg:gap-4">
          <div className="flex w-full items-center justify-between gap-1 self-center text-xs font-semibold text-primary">
            <span className="lg:text-sm lg:font-medium lg:leading-snug">
              {t('basket.mini_basket.subtotal')}
            </span>
            <Price
              className="lg:text-lg lg:font-semibold lg:leading-normal"
              value={basket?.total_amount}
            />
          </div>
          <div className="flex flex-col gap-4 lg:flex-row ">
            <Link
              onClick={() => {
                dispatch(closeMiniBasket());
              }}
              href={ROUTES.BASKET}
              data-testid="mini-basket-view-bag"
              className="flex h-10 items-center justify-center whitespace-nowrap border border-primary px-4 text-base font-normal text-primary transition-all hover:bg-primary hover:text-primary-foreground"
            >
              {t('basket.mini_basket.view_bag')}
            </Link>
            <Link
              href={ROUTES.CHECKOUT}
              className="flex h-10 items-center justify-center whitespace-nowrap border border-primary bg-primary px-2.5 text-base font-normal text-primary-foreground transition-all hover:bg-primary-foreground hover:text-primary"
              data-testid="mini-basket-continue-shopping"
            >
              {t('basket.mini_basket.continue_shopping')}
            </Link>
          </div>
        </footer>
      </div>
    </>
  );
}
