'use client';
import { Button, Modal } from '@theme/components';
import { useGetWidgetQuery } from '@akinon/next/data/client/misc';
import { useLocalization } from '@akinon/next/hooks';

export function FooterSubscriptionPrivacyModal({
  isModalOpen,
  setIsModalOpen
}) {
  const { data: privacyPolicy } = useGetWidgetQuery('privacy-policy');
  const { t } = useLocalization();
  return (
    <Modal
      className="flex h-4/5 w-4/5 flex-col items-center justify-between p-4 md:p-12 lg:w-1/2"
      portalId="footer-subscription-privacy-policy-modal"
      open={isModalOpen}
      setOpen={setIsModalOpen}
      showCloseButton={false}
      title={privacyPolicy?.attributes?.title?.value}
    >
      <div
        className="mb-3 w-full overflow-y-auto"
        dangerouslySetInnerHTML={{
          __html: privacyPolicy?.attributes?.description?.value
        }}
      />

      <Button
        onClick={() => setIsModalOpen(false)}
        appearance="outlined"
        className="px-10 py-2 font-medium"
        data-testid="newsletter-modal-close-button"
      >
        {t('common.newsletter_signup.close')}
      </Button>
    </Modal>
  );
}
