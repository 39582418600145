'use client';

import { useState, useEffect } from 'react';
import { Link } from '@theme/components';
import clsx from 'clsx';
import { ROUTES } from '@theme/routes';
import ActionMenu from './action-menu';
import HeaderBand from './band';
import MobileHamburgerButton from './mobile-hamburger-button';
import MobileMenu from './mobile-menu';
import Navbar from './navbar';
import { Image } from '@akinon/next/components/image';
import Search from './search';
import { useMediaQuery } from '@akinon/next/hooks';
import { useAppSelector } from '@akinon/next/redux/hooks';

export default function HeaderContent({ data, menu }) {
  const [scrollY, setScrollY] = useState(0);
  const [isHidden, setIsHidden] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const { openedMenu } = useAppSelector((state) => state.header);

  useEffect(() => {
    const handleScroll = () => {
      if (!isMobile) return;

      const currentScrollY = window.scrollY;
      setIsTop(currentScrollY === 0);
      setIsHidden(currentScrollY > scrollY && currentScrollY > 0);
      setScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollY, isMobile]);

  const headerClass = clsx(
    'header-m-template-cols header-grid-template-areas mx-auto mt-10 grid items-center bg-white sm:grid-cols-3 lg:mt-0',
    {
      hidden: isMobile && isHidden && !isTop,
      'fixed left-0 top-0 z-50 w-full': isMobile && !isTop,
      relative: !isMobile || isTop
    },
    openedMenu && 'lg:z-50'
  );

  const openedMenuHasChildren =
    openedMenu &&
    menu?.find((menuItem) => menuItem?.pk == openedMenu)?.children?.length > 0;
    const isSearchOpen = useAppSelector((state) => state.header.isSearchOpen);

  return (
    <>
      {openedMenuHasChildren && (
        <div className="left-0 top-0 z-40 h-full w-full bg-primary opacity-50 lg:fixed"></div>
      )}
      <HeaderBand data={data} />
      <div className={headerClass}>
        <div className="container col-span-full flex h-full items-center justify-between lg:px-0">
          <div className="flex w-full items-center justify-start gap-2 lg:gap-16">
            <MobileHamburgerButton />
            <Link
              href={ROUTES.HOME}
              passHref={true}
              className={clsx(
                "flex h-16 w-[116px] items-center self-center sm:justify-self-center lg:my-3 lg:ml-0 lg:w-64 lg:justify-self-auto",
                isSearchOpen && 'ml-auto'
              )}
              aria-label="Homepage"
            >
              <Image
                src="/logo.svg"
                alt="Paşabahçe Mağazaları"
                fetchPriority="high"
                width={116}
                loading="eager"
                height={40}
                className="block w-full"
                style={{ height: 'auto', width: '100%' }}
                unoptimized
              />
            </Link>
            <Search />
          </div>

          <ActionMenu />
        </div>

        <MobileMenu data={data} menu={menu} />
      </div>
      <div
        className={clsx(
          'relative border-t border-lightBlue bg-white lg:border-b',
          openedMenuHasChildren && 'z-50'
        )}
      >
        <div className="sm:container lg:px-0">
          <Navbar menu={menu} />
        </div>
      </div>
    </>
  );
}
