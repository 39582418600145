'use client';

import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import {
  closeSearch,
  toggleMobileMenu
} from '@akinon/next/redux/reducers/header';
import clsx from 'clsx';

import { PwaBackButton } from './pwa-back-button';
import { Button, Icon } from '@theme/components';

export default function MobileHamburgerButton() {
  const dispatch = useAppDispatch();
  const isSearchOpen = useAppSelector((state) => state.header.isSearchOpen);
  const icon = isSearchOpen ? 'close' : 'hamburger';
  const iconSize = isSearchOpen ? 32 : 16;
  const handleClick = () => {
    if (isSearchOpen) {
      dispatch(closeSearch());
      return;
    }
    dispatch(toggleMobileMenu());
  };

  return (
    <div className="row-start-2 flex lg:hidden">
      <PwaBackButton />
      <Button
        appearance="ghost"
        className={clsx([
          'h-8 w-8',
          'flex',
          'items-center',
          'justify-center',
          'px-0',
          'border-none'
        ])}
        onClick={handleClick}
      >
        <Icon
          name={icon}
          size={iconSize}
          className={clsx('fill-white', {
            'h-4 w-[21px]': icon === 'hamburger'
          })}
        />
      </Button>
    </div>
  );
}
