'use client';

import { useEffect, useState } from 'react';
import { MenuItemType } from '@akinon/next/types';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import { closeMobileMenu } from '@akinon/next/redux/reducers/header';
import clsx from 'clsx';

import { UserMenu } from './user-menu';
import {
  Button,
  CurrencySelect,
  Icon,
  LanguageSelect,
  Link
} from '@theme/components';
import HeaderBand from './band';
import { Image } from '@akinon/next/components/image';

interface MobileMenuProps {
  menu: MenuItemType[];
  data: any;
}

export default function MobileMenu(props: MobileMenuProps) {
  const { menu, data: headerbandData } = props;

  const dispatch = useAppDispatch();
  const [selectedSubMenu, setSelectedSubMenu] = useState<MenuItemType | null>(
    null
  );
  const [selectedSubMenuChildren, setSelectedSubMenuChildren] = useState(null);

  const isMobileMenuOpen = useAppSelector(
    (state) => state.header.isMobileMenuOpen
  );

  const handleClickSubMenu = (item) => {
    if (!item.children || item.children.length === 0) return;

    setSelectedSubMenu(null);
    setSelectedSubMenuChildren(item);
  };

  const handleClickSubMenuChildren = () => {
    setSelectedSubMenuChildren(null);
    dispatch(closeMobileMenu());
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add('mobile-overflow-hidden');
    }
    return () => {
      document.body.classList.remove('mobile-overflow-hidden');
    };
  }, [isMobileMenuOpen]);

  return (
    <>
      {/* MENU OVERLAY */}

      <div
        className={clsx(
          'invisible fixed left-0 top-0 z-30 flex h-full w-full -translate-x-72 transform flex-col bg-white opacity-0 transition duration-500',
          {
            '!visible translate-x-0 !opacity-100': isMobileMenuOpen
          }
        )}
      >
        <HeaderBand data={headerbandData} className="relative" />
        <div className="mx-4">
          <div className="mt-3 flex items-start justify-between">
            <Icon
              name="plus"
              size={23}
              className="max-w-fit rotate-45 cursor-pointer"
              onClick={() => dispatch(closeMobileMenu())}
            />
            <Link href="/" className="cursor-pointer">
              <Image
                src="/logo.svg"
                alt="Paşabahçe Mağazaları"
                width={116}
                height={40}
                className="w-[116px]"
                style={{ height: 'auto', width: '100%' }}
                unoptimized
              />
            </Link>
          </div>
          <div
            className={clsx(
              'relative mb-6 mt-4 flex items-center justify-between pt-[10px]',

              {
                hidden: selectedSubMenu || selectedSubMenuChildren
              },
              [
                'after:right-[173px]',
                'after:translate-x-full',
                'after:content-[""]',
                'after:w-full',
                'after:h-[1px]',
                'after:block',
                'after:absolute',
                'after:-top-[10px]',
                'after:transform',
                'after:bg-lightBlue'
              ],
              [
                'before:-left-[500px]',
                'before:translate-x-full',
                'before:content-[""]',
                'before:w-full',
                'before:h-[1px]',
                'before:block',
                'before:absolute',
                'before:-top-[10px]',
                'before:transform',
                'before:bg-lightBlue'
              ]
            )}
          >
            <UserMenu
              isMobile
              className='relative
                        h-[1px]
                        after:absolute
                        after:-bottom-[18px]
                        after:-left-[141px]
                        after:block
                        after:h-full
                        after:w-full
                        after:translate-x-full
                        after:transform
                        after:bg-primary
                        after:content-[""]
                      '
            />

            <li className="ml-[18px] flex items-center">
              <LanguageSelect className="!h-auto !w-full px-0 text-sm text-gray-850 opacity-0" />
              <CurrencySelect className="!h-auto !w-full px-0 text-sm text-gray-850 opacity-0" />
            </li>
          </div>
        </div>
        <div className="relative flex flex-1 flex-col justify-between overflow-x-hidden">
          <ul
            className={clsx('transform px-4 transition duration-500 ', {
              '-translate-x-full': selectedSubMenu || selectedSubMenuChildren
            })}
          >
            {menu.map(
              (item, index) =>
                item.label != null &&
                item.extra_context.attributes.alignment?.value !== 'right' && (
                  <li key={index} className="mt-4 flex h-10 w-full">
                    <Link
                      href={item.url}
                      onClick={(e) => {
                        if (item.children.length > 0) {
                          e.preventDefault();
                          setSelectedSubMenu(item);
                        }
                      }}
                      className="flex w-full items-center justify-between"
                    >
                      <span
                        style={{
                          color: item.extra_context.attributes.color?.value
                        }}
                      >
                        {item.label}
                      </span>
                      {!item.extra_context.attributes.color && (
                        <Icon name="chevron-end" size={14} />
                      )}
                    </Link>
                  </li>
                )
            )}
          </ul>
          <div className="flex flex-col">
            <ul
              className={clsx('mt-12 transform transition duration-500', {
                '-translate-x-full': selectedSubMenu || selectedSubMenuChildren
              })}
            >
              {menu.map(
                (item, index) =>
                  item.label != null &&
                  item.extra_context.attributes.alignment?.value ===
                    'right' && (
                    <li
                      key={index}
                      className="flex w-full border border-lightBlue p-4 text-sm font-semibold"
                    >
                      <Link
                        href={item.url}
                        onClick={(e) => {
                          if (item.children.length > 0) {
                            e.preventDefault();
                            setSelectedSubMenu(item);
                          }
                        }}
                        className="flex w-full items-center justify-between"
                      >
                        <span
                          style={{
                            color: item.extra_context.attributes.color?.value
                          }}
                        >
                          {item.label}
                        </span>
                        {item.extra_context.attributes.color?.value ==
                          false && <Icon name="chevron-end" size={14} />}
                      </Link>
                    </li>
                  )
              )}
            </ul>
            <ul
              className={clsx('flex flex-wrap', {
                hidden: selectedSubMenu || selectedSubMenuChildren
              })}
            >
              {headerbandData?.attributes?.header_band_items?.map(
                (item, index) =>
                  item.value.name != null && (
                    <li
                      key={index}
                      className="flex h-11 w-1/2 flex-wrap items-center justify-center border border-lightBlue bg-gray-100 text-sm font-semibold"
                    >
                      <Link
                        href={item?.value?.redirect_url}
                        className="flex w-full items-center justify-center"
                      >
                        <span>{item?.value?.name}</span>
                      </Link>
                    </li>
                  )
              )}
            </ul>
          </div>
          <div
            className={clsx(
              'invisible absolute left-0 right-0 top-0 flex h-full translate-x-full transform flex-col justify-between bg-white px-4 opacity-0 transition duration-500',
              {
                '!visible !translate-x-0 !opacity-100': selectedSubMenu
              }
            )}
          >
            <div>
              <header
                className={clsx(
                  'relative mb-4 flex h-[61px] items-center justify-between gap-x-[15px] py-4',
                  [
                    'after:right-[173px]',
                    'after:translate-x-full',
                    'after:content-[""]',
                    'after:w-full',
                    'after:h-[2px]',
                    'after:block',
                    'after:absolute',
                    'after:-top-[1px]',
                    'after:transform',
                    'after:bg-lightBlue'
                  ],
                  [
                    'before:-left-[500px]',
                    'before:translate-x-full',
                    'before:content-[""]',
                    'before:w-full',
                    'before:h-[2px]',
                    'before:block',
                    'before:absolute',
                    'before:-top-[1px]',
                    'before:transform',
                    'before:bg-lightBlue'
                  ]
                )}
              >
                <Button
                  appearance="ghost"
                  onClick={() => setSelectedSubMenu(null)}
                  className="flex items-center gap-2 !p-0 text-xs font-semibold"
                >
                  <Icon name="chevron-start" size={12} className="shrink-0" />
                  <span className="text-sm font-normal">
                    {selectedSubMenu?.label}
                  </span>
                </Button>
              </header>
              <div className="flex flex-col justify-between">
                <ul className="flex h-full flex-col">
                  {selectedSubMenu?.children.map((child, index) => {
                    const hasChildren =
                      child.children && child.children.length > 0;

                    return hasChildren ? (
                      <li
                        key={index}
                        onClick={() => handleClickSubMenu(child)}
                        className="flex items-center justify-between py-4 text-base"
                      >
                        <span>{child.label}</span>
                        <Icon name="chevron-end" size={14} />
                      </li>
                    ) : (
                      <li key={index}>
                        <Link
                          href={child.url}
                          className="flex items-center justify-between py-4 text-base"
                        >
                          <span>{child.label}</span>
                        </Link>
                      </li>
                    );
                  })}
                  {selectedSubMenu?.extra_context?.attributes
                    ?.navigation_menu_footer &&
                    selectedSubMenu?.extra_context?.attributes?.navigation_menu_footer.map(
                      (item, index) => (
                        <li
                          key={index}
                          className="flex items-center justify-between py-4 text-base"
                        >
                          {item?.value?.title && (
                            <>
                              <Link href={item?.value?.url}>
                                <span>{item?.value?.title}</span>
                              </Link>
                            </>
                          )}
                        </li>
                      )
                    )}
                </ul>
              </div>
            </div>
            <>
              {selectedSubMenu?.children.map(
                (child, index) =>
                  child?.extra_context?.attributes?.all_product?.value && (
                    <Link
                      key={index}
                      className="-ml-[10%] flex h-16 w-[120%] items-center justify-start gap-2 border-t border-lightBlue bg-gray-25
                     "
                      href={
                        child?.extra_context?.attributes?.all_product?.value.url
                      }
                    >
                      <span className="ml-[4%] pl-[4%] text-sm underline">
                        {
                          child?.extra_context?.attributes?.all_product?.value
                            .description
                        }
                      </span>
                      <Icon name="chevron-end" size={14} />
                    </Link>
                  )
              )}
            </>
          </div>
          <div
            className={clsx(
              'invisible absolute left-0 right-0 top-0 translate-x-full transform bg-white px-4 opacity-0 transition duration-500',
              {
                '!visible !translate-x-0 !opacity-100': selectedSubMenuChildren
              }
            )}
          >
            <header
              className={clsx(
                'gap-x-[15px]py-4 relative mb-4 flex h-[61px] items-center  justify-between',
                [
                  'after:right-[173px]',
                  'after:-top-[1px]',
                  'after:translate-x-full',
                  'after:content-[""]',
                  'after:w-full',
                  'after:h-[2px]',
                  'after:block',
                  'after:absolute',
                  'after:-bottom-[1px]',
                  'after:transform',
                  'after:bg-lightBlue'
                ],
                [
                  'before:-left-[500px]',
                  'before:translate-x-full',
                  'before:content-[""]',
                  'before:w-full',
                  'before:h-[2px]',
                  'before:block',
                  'before:absolute',
                  'before:-top-[1px]',
                  'before:transform',
                  'before:bg-lightBlue'
                ]
              )}
            >
              <Button
                appearance="ghost"
                onClick={() => setSelectedSubMenuChildren(null)}
                className="flex items-center gap-2 !p-0 text-xs font-semibold"
              >
                <Icon name="chevron-start" size={12} className="shrink-0" />
                <span className="text-sm font-normal">
                  {selectedSubMenuChildren?.label}
                </span>
              </Button>
            </header>
            <div className="flex flex-col">
              {selectedSubMenuChildren?.children?.map((child, index) => (
                <div key={index} className="flex flex-col">
                  <ul>
                    <li key={index} className="">
                      <Link
                        onClick={() => handleClickSubMenuChildren()}
                        href={child.url}
                        className="flex items-center justify-between py-4 text-base"
                      >
                        <span>{child.label}</span>
                        <Icon name="chevron-end" size={14} />
                      </Link>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
